/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import o from "../core/Accessor.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
let e = class extends o {
  constructor(r) {
    super(r), this.created = null, this.rating = null;
  }
};
r([s()], e.prototype, "created", void 0), r([s()], e.prototype, "rating", void 0), e = r([t("esri.portal.PortalRating")], e);
const c = e;
export { c as default };